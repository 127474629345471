import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//y230522 shield the useless function
// import { getAccessToken } from '@/utils/auth'
import { getToken } from "@/util/auth";
import { logout } from "./api/login";
// import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false });

//y230522
// 增加三方登陆 update by 芋艿
// const whiteList = ['/login', '/social-login',  '/auth-redirect', '/bind', '/register', '/oauthLogin/gitee']
//y230916 增加白名单内容，增加用户指南 y240222
const whiteList = [
  "/login",
  "/download",
  "/userManual",
  "/enmanual",
  "/userManualVideo",
  "/privacy",
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  //y230522 合并router相关的代码

  const hasToken = getToken();

  //   if (getAccessToken()) {
  if (hasToken && hasToken !== "undefined") {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === "/login") {
      //y230523
      next();
      //   next({ path: '/' })
      //   next({ path: '/messageList' })
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // isRelogin.show = true
        // 获取字典数据 add by 芋艿
        store.dispatch("dict/loadDictDatas");
        // 判断当前用户是否已拉取完 user_info 信息
        store
          .dispatch("GetInfo")
          .then(() => {
            //   isRelogin.show = false
            store.dispatch("GenerateRoutes").then((accessRoutes) => {
              // console.log(router.getRoutes());
              // console.log(router.options.routes, "===已有路由");
              // console.log(accessRoutes, "===接入路由");

              // router.addRoute({
              //     path: '/xitong2',//
              //     component: () => import('@/components/device/mobile.vue'),
              //     name: 'Xitong2',
              //     meta: {
              //         title: "menu.mobileDevice",
              //         query: {
              //             systemId: 'a02'
              //         }
              //     }
              // })

              // path: "/mobileList", component: () => import('@/components/device/mobile'), name: "mobileList", meta: { title: "menu.mobileDevice" }

              // 根据 roles 权限生成可访问的路由表
              // router.addRoutes(accessRoutes) // 动态添加可访问路由表
              let toRoute = null;
              //在vue3中，需要使用addRoute y230527
              accessRoutes.forEach((itemRouter) => {
                router.addRoute(itemRouter);

                if (toRoute == null && !itemRouter?.hidden) {
                  if (
                    itemRouter?.children &&
                    Array.isArray(itemRouter.children)
                  ) {
                    toRoute = itemRouter.children[0];
                  } else {
                    toRoute = itemRouter;
                  }
                }
              });
              //改变我的路由缓存数据，y230528
              // store.state.app.myroutesdata = router.getRoutes();
              //y230527 刷新当前路由,确保添加路由的操作生效
              // next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
              if (toRoute != null) {
                if (to.path == "/") {
                  next(toRoute?.path);
                } else {
                  next({ ...to, replace: true });
                }
                console.log("toRoute的测试", to, toRoute);
              } else {
                // next("/");
                next();
                console.log("toRoute的测试next ", to);
              }
            });
            // next();
          })
          .catch((err) => {
            //y230628
            //y230523 if error, jump to login page
            //Message.error(err)
            // next(`/login?redirect=${to.path}`)
            store
              .dispatch("LogOut")
              .then(() => {
                // Message.error(err)
                console.log(err);
                //y230629 如果是白名单中，则放行访问连接，因为已经清除了token，让连接跳转到登录页面
                //如果是非白名单中，则要调转到根目录/
                // next({ path: '/' })
                // next();
                if (whiteList.indexOf(to.path) !== -1) {
                  next();
                } else {
                  next({ path: "/" });
                }
              })
              .catch((logouterr) => {
                //如果发生错误，则直接清空所有存储
                //发生错误，表示服务端不存在用户登录的信息，清空信息后重新登录
                console.log(err);
                console.log(logouterr);
                // next();
                if (whiteList.indexOf(to.path) !== -1) {
                  next();
                } else {
                  next({ path: "/" });
                }
              });
          });
        //y230522 debug code
        //   next({path:'/'})
      } else {
        next();
      }
    }
  } else {
    //如果未登录，则行白名单放行检查，否则直接跳转到登录页面。york230504
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      //y230522 先保留代码
      const redirect = encodeURIComponent(to.fullPath); // 编码 URI，保证参数跳转回去后，可以继续带上
      next(`/login?redirect=${redirect}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

// router.afterEach(() => {
//   NProgress.done()
// })

//y230522
router.afterEach((to, from) => {
  NProgress.done();
  //y240517 防止重载/404时的错误提示
  // if (!to.name) {
  //     router.replace('/404')
  // }
});
